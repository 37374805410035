import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom'

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);
  const location = useLocation();

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          {/* <AppsNavigation /> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            <Menu.Item className="menuItem" key="Alert Map">
              <Link to="/alert-map"><i className={location.pathname == '/alert-map' ? 'icon icon-map-drawing menuItem' : 'icon icon-map-drawing'} />
                <span className={location.pathname == '/alert-map' ? 'menuItem' : ''}>Alert Map</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="Management">
              <Link to="/management"><i className={location.pathname == '/management' ? 'icon icon-lising-dbrd menuItem' : 'icon icon-lising-dbrd'} />
                <span className={location.pathname == '/management' ? 'menuItem' : ''}>Management</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="Reports">
              <Link to="/reports"><i className={location.pathname == '/reports' ? 'icon icon-files menuItem' : 'icon icon-files'} />
                <span className={location.pathname == '/reports' ? 'menuItem' : ''}>Reports</span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="Contact Information">
              <Link to="/contact-information"><i className="icon icon-contacts" />
                <span>Contact Information</span>
              </Link>
            </Menu.Item> */}

            <Menu.Item key="responderDashboard">
              <Link to="/responder-dashboard"><i className={location.pathname == '/responder-dashboard' ? 'icon icon-alert menuItem' : 'icon icon-alert'} />
                {/* <span><IntlMessages id="sidebar.responder" /></span> */}
                <span className={location.pathname == '/responder-dashboard' ? 'menuItem' : ''}>Responders</span>
              </Link>
            </Menu.Item>

          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

