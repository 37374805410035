import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import { Row, Col, Form, Button, Divider, message } from "antd";
import axios from "../../util/Api";
import {useAuth} from "../../authentication";
import Countdown from 'react-countdown';

const SessionTimeout = ({ toggleSession, countdownValue }) => {
  const {userSignOut} = useAuth();
  const history = useHistory();
  const [loadingButton, setLoading] = useState(false);
  var clockRef = null;

  const fail = (errorMessage) => {
    message.config({
      top: 65,
    });
    message
      .loading("Action in progress..", 2)
      .then(() => message.error(errorMessage, 2.5));
  };

  const onLogout = () => {
    userSignOut(() => {
      history.push('/');
    });
    toggleSession();
  }

  const stayConnected = () => {
    setLoading(true)
 
    //METHOD: POST
    //MODULE: SESSION EXPIRED MODULE
    //DESCRIPTION: CONTINUE SESSION
    axios.post(`/login/refresh/`, {
      refresh: localStorage.getItem("refresh_token")
    })
    .then(res=>{
      localStorage.setItem('token', res.data.access);
      localStorage.setItem('refresh_token', res.data.refresh);
      toggleSession();
    })
    .catch(error =>{
      // this.fail(error.response.data.detail)
      fail("Your session has expired. Please login again.");
      toggleSession();
      userSignOut(() => {
        history.push('/');
      });
      setLoading(false)
    })  
  }

  const setClockRef = (ref) => {
    clockRef = ref;
  }

  return (
    <div>
        <Form>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
             <h4 style={{ fontWeight: '600', marginBottom: 13 }}>Your session has about to expire.</h4>
             <h5>Redirecting in <Countdown date={Date.now() + (countdownValue * 1000)} renderer={(props) => <span style={{ color: "red" }}>{props.seconds}</span>} autoStart={true} onComplete={onLogout}
                  ref={setClockRef} /> seconds ...</h5>
            </Col>
          </Row>
          <Divider />
          <div layout="inline" className="gx-text-right">
            <Button
              key="reset"
              type="danger"
              size="medium"
              onClick={onLogout}
              ghost
            >
              Logout
            </Button>
            <Button
              type="primary" size="medium"
              loading={loadingButton}
              onClick={stayConnected}
            >
              Stay Connected
            </Button>
          </div>
        </Form>
      </div>
  )
};

export default SessionTimeout;
