import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}my-account`} component={asyncComponent(() => import('../containers/Sidebar/Account'))} />
      <Route path={`${match.url}alert-map`} component={asyncComponent(() => import('./AlertMap'))} />
      <Route path={`${match.url}manual-incident-report`} component={asyncComponent(() => import('./AlertMap/ManualIncidentReport/IncidentReport'))} />
      <Route path={`${match.url}management`} component={asyncComponent(() => import('./Management'))} />
      <Route path={`${match.url}manual-responder-monitoring`} component={asyncComponent(() => import('./Management/Manual/ResponderMonitoring'))} />
      <Route path={`${match.url}responder-monitoring`} component={asyncComponent(() => import('./Management/InApp/ResponderMonitoring'))} />
      <Route path={`${match.url}reports`} component={asyncComponent(() => import('./Reports'))} />
      <Route path={`${match.url}report-details`} component={asyncComponent(() => import('./Reports/ReportDetails'))} />
      <Route path={`${match.url}contact-information`} component={asyncComponent(() => import('./ContactInformation/ContactTable'))} />
      <Route path={`${match.url}responder-dashboard`} component={asyncComponent(() => import('./Responders'))} />
      <Route path={`${match.url}responder-details`} component={asyncComponent(() => import('./Responders/ResponderList/viewResponder'))} />
    </Switch>
  </div>
);

export default App;
